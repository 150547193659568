import React from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";   
import PagesItems from '../components/PagesItems';
import { SunIcon } from "@heroicons/react/solid";

 
const Page = ({ location, pageContext }) => {
 
  return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageContext.title} | Domy Trio</title> 
        </Helmet>
        <Layout location={location}>
          
              <div className="mx-auto w-full  relative z-30  ">
                  <div className="mx-auto max-w-6xl w-full p-8 lg:px-14 bg-white shadow-md z-auto border border-gray-200 border-t-2 relative">    
                      <svg className="absolute w-60 h-60 text-yellow-400 top-0 right-0 ">
                            <polygon points="0,0 300,0 300,300" class="triangle" fill="currentColor" />
                            <SunIcon width="50" x="160" y="-70"  fill="#ffffff" />
                        </svg>
                        <div className="relative">
                    <div className="">
                        <h1 className="text-xl lg:text-3xl font-merri mb-10 border-b border-yellow-400 pb-3">{pageContext.title}</h1> 
                    </div>
                    
                        <div className="relative px-4 sm:px-6 lg:px-8   lg:pb-20 pb-20">
                          
                          <div dangerouslySetInnerHTML={{__html: pageContext.pageContent}} className="mt-6 prose prose-yellow prose-sm max-w-6xl   text-gray-600 mx-auto pb-10"> 
                          
                            </div>
                          <div className=" prose prose-blue lg:prose-lg max-w-6xl text-gray-500 mx-auto pb-10"> 
                            <PagesItems pageContext={pageContext} /> 
                          
                            </div>
                        </div>
                                    
                      </div>
                    </div>

                  </div>
        </Layout>
      </>

  )
}
 
export default Page